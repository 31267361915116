.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    background-color: #4e4800;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #ffffff;
    z-index: 100;
    right: 15px;
  }
  .my-float {
    margin-top: 16px;
  }