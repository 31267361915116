@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary:#3F86F8;
  --background:#f5f811;
  --accent-color: #ff4a17;
}

.primary {
  color: var(--primary);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul{
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 1240px;
  margin: auto;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.4rem;
  line-height: 1.2;
}

h5{
  font-size: 1.1rem;
  line-height: 1.2;
}

p{
  font-size: 1.2rem;
}

.btn {
  padding: 14px 32px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: white;
  border-radius: 10px ;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  box-shadow:rgb(000/15%)0px 8px 24px;
}


input {
  padding:12px 32px;
  border: 1px solid var(--primary);
  background: transparent;
  border-radius: 24px 4px;
  font-size: 1rem;
  margin-right: .8rem;
  font-family: 'Poppins',sans-serif;

}


@media screen and (max-width:768px) {

  h1 {
    font-size: 2.1rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  h5{
    font-size: 1.1rem;
    line-height: 1.2;
  }

  p{
    font-size: 1rem;
  }

  .btn{
    width: 100%;
    margin: 1rem 0;
  }

  input {
    width: 100%;
  }

}



body {
  margin: 0;
  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* navbar css start from here */

.header {
    width: 100%;
    height: 90px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1rem;
}

.colors {
    color: var(--primary);
}
  
.nav-menu {
    display: flex;
    align-items: center;
}

.logo
{
    width: 100px;
    height: auto;
    object-fit: fill; /* or contain */
    padding: 10px;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.nav-menu a.active {
  color: var(--accent-color); /* Change text color for active link */
  border: 0px solid var(--background); /* Optional: add border */
}

.nav-menu a:hover {
  color: var(--accent-color); /* Change text color for active link */
  border: 0px solid var(--background); /* Optional: add border */
}

.hamburger {
    display: none;
    cursor: pointer;
}


@media screen and (max-width:1240px) {
    .hamburger {
        display: flex;
    }

    .nav-menu{
        position: absolute;
        flex-direction: column;
        justify-content: start;
        top: 90px;  
        right: 50%;
        width: 50%;
        height: 100vh;
        transition: 0.5s ease;
        z-index: 2;
        background: rgb(0, 0, 0);
        border-left: 1px solid #000000;
        visibility: hidden;
    }

    .nav-menu.active {
        right: 0;
        visibility: visible;
    }

    .nav-menu a.active {
      color: var(--accent-color); /* Change text color for active link */
      background-color: #000000; /* Change background color for active link */
      border: 0px solid var(--background); /* Optional: add border */
    }

    .nav-menu li {
        padding: 1rem;
        width: 100%;
        border-bottom: 1px solid #000000;
    }

    .nav-menu a {
        font-size: 1.2rem;
        text-decoration: none;
    }
}